<template>
  <li
    v-if="canViewVerticalNavMenuGroup(item) && hasPermission(item.permissionName)"
    class="nav-item has-sub"
    :class="{
      'open': isOpen,
      'disabled': item.disabled,
      'sidebar-group-active': isActive,
    }"
    @click="setPositionNavbar(item)"
  >
    <b-link
      class="d-flex align-items-center w-100"
      :class="{'justify-content-between': mouseHover}"
      @click="updateNavbar(item.root)"
    >
      <div :class="{'narrow-wrap': !mouseHover}">
        <img
          v-if="item.svg"
          v-b-tooltip.hover.right.v-secondary
          :title="item.title"
          :src="require(`@/assets/images/menu/${item.svg}`)"
          class="mr-1"
          style="width: 20px; height: 20px; margin-right: 1.1rem;"
        >
        <feather-icon
          v-else
          v-b-tooltip.hover.right.v-secondary
          :title="item.title"
          :icon="item.icon || 'CircleIcon'"
        />
        <span
          class="menu-title"
          style="font-size: 14px; color: #000000;"
        >{{ t(item.title) }}</span>
        <b-badge
          v-if="item.tag"
          pill
          :variant="item.tagVariant || 'primary'"
          class="mr-1 ml-auto"
        >
          {{ item.tag }}
        </b-badge>
      </div>
      <div
        v-if="getDocumentNumber(item)"
        class="doc-number"
        :class="{narrow: !mouseHover}"
      >
        {{ getDocumentNumber(item) }}
      </div>
    </b-link>
    <b-collapse
      v-model="isOpen"
      class="menu-content"
      tag="ul"
    >
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.header || child.title"
        ref="groupChild"
        :item="child"
        :mouse-hover="mouseHover"
        :child="true"
      />
    </b-collapse>
  </li>
</template>

<script>
import {
  BLink,
  BBadge,
  BCollapse,
  VBTooltip,
} from 'bootstrap-vue'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { hasPermission } from '@/utils/permission-utils'
import { mapGetters } from 'vuex'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'

export default {
  name: 'VerticalNavMenuGroup',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
    mouseHover: {
      type: Boolean,
    },
  },
  data() {
    return {
      hasPermission,
    }
  },
  computed: {
    ...mapGetters({
      documentNumber: 'common/documentNumber',
    }),
  },
  mounted() {
    this.$eventBus.$on('cancel-navbar', this.cancelNavbar)
  },
  methods: {
    cancelNavbar() {
      if (!this.mouseHover) {
        this.updateGroupOpen(false)
      }
    },
    updateNavbar(isRoot) {
      if (isRoot) {
        this.$eventBus.$emit('cancel-navbar')
      }
      this.updateGroupOpen(!this.isOpen)
    },
    getDocumentNumber(item) {
      const childrenCode = item.children.filter(child => this.hasPermission(child.permissionName)).map(child => child.code)
      const doc = this.documentNumber.filter(d => childrenCode.includes(d.code))
      if (doc.length > 0) {
        const number = doc.reduce((total, next) => total + next.count, 0)
        return number > 99 ? '99+' : number
      }
      return ''
    },
    setPositionNavbar() {
      this.$nextTick(() => {
        const parent = document.querySelector('.menu-relative .open')
        const child = document.querySelector('.menu-relative .open .menu-content')
        if (parent) {
          const rect = parent.getBoundingClientRect()
          child.style.position = 'fixed'
          child.style.backgroundColor = '#f5f5f5'
          child.style.border = '3px solid white'
          child.style.top = `${rect.top}px`
          child.style.left = `${rect.left + 80}px`
          child.style.maxHeight = '450px'
          child.style.minWidth = '300px'
          child.style.overflow = 'hidden auto'
        }
        const removeStyelElement = document.querySelector('.menu-default .open .menu-content')
        if (removeStyelElement) {
          removeStyelElement.style.position = 'unset'
          removeStyelElement.style.backgroundColor = 'white'
        }

        const removeStyelElement2 = document.querySelector('.menu-relative .open .open .menu-content')
        if (removeStyelElement2) {
          removeStyelElement2.style.position = 'unset'
          removeStyelElement2.style.backgroundColor = '#f8f8f8'
        }
      })
    },
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item)

    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuGroup } = useAclUtils()

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,
    }
  },
}
</script>
<style scoped>
.doc-number {
  margin-right: 40px;
}
.narrow-wrap {
  width: 22px !important;
}
</style>
