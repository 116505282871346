import { ROUTE_NAME } from '@/modules/tra-ket-qua/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

export default [
  {
    title: 'Trả kết quả',
    root: true,
    svg: 'tra-ket-qua-icon.svg',
    permissionName: [PERMISSION_NAME.HO_SO_GIAI_QUYET.DANH_SACH, PERMISSION_NAME.HO_SO_TU_CHOI_GIAI_QUYET.DANH_SACH],
    children: [
      {
        title: 'Hồ sơ giải quyết',
        icon: 'ArrowRightIcon',
        code: ROUTE_NAME.HS_GIAI_QUYET.NAME,
        route: ROUTE_NAME.HS_GIAI_QUYET.DS_HS,
        permissionName: [PERMISSION_NAME.HO_SO_GIAI_QUYET.DANH_SACH],
      },
      {
        title: 'Hồ sơ từ chối giải quyết',
        icon: 'ArrowRightIcon',
        code: ROUTE_NAME.HS_KHONG_GIAI_QUYET.NAME,
        route: ROUTE_NAME.HS_KHONG_GIAI_QUYET.DS,
        permissionName: [PERMISSION_NAME.HO_SO_TU_CHOI_GIAI_QUYET.DANH_SACH],
      },
    ],
  },
]
