import { ROUTE_NAME } from '@/modules/nhan-ket-qua/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

export default [
  {
    title: 'Nhận kết quả',
    svg: 'nhan-ket-qua-icon.svg',
    root: true,
    code: 'nhan-ket-qua-hs',
    route: ROUTE_NAME.HS_KET_QUA.DS_NHAN_HS,
    permissionName: [PERMISSION_NAME.NHAN_KET_QUA_HO_SO.DANH_SACH],
  },
]
