import {
  ROUTE_NAME,
  MENU_ROUTE_NAME,
} from '@/modules/quan-ly-quy-trinh/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

export default [
  {
    title: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.NAME,
    svg: 'quy-trinh-icon.svg',
    root: true,
    permissionName: [PERMISSION_NAME.QUY_TRINH_DONG.DANH_SACH, PERMISSION_NAME.CAU_HINH_QUY_TRINH_DONG.DANH_SACH],
    children: [
      {
        title: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY,
        route: ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY + ROUTE_NAME.QUAN_LY_QUY_TRINH.DANH_SACH,
        icon: 'ArrowRightIcon',
        permissionName: [PERMISSION_NAME.QUY_TRINH_DONG.DANH_SACH],
      },
      {
        title: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.CAU_HINH_QUY_TRINH,
        route: ROUTE_NAME.QUAN_LY_QUY_TRINH.CAU_HINH_QUY_TRINH,
        icon: 'ArrowRightIcon',
        permissionName: [PERMISSION_NAME.CAU_HINH_QUY_TRINH_DONG.DANH_SACH],
      },
    ],
  },
]
