<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
      isMouseHovered ? 'menu-default' : 'menu-relative',
    ]"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row d-flex justify-content-between">

          <!-- Logo & Text -->
          <li class="nav-item">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span
                class="brand-logo"
                :class="isVerticalMenuCollapsed && !isMouseHovered ? '' : 'd-none'"
              >
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2
                class="brand-text text-wrap pl-0"
              >
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                v-b-tooltip.hover.right.v-secondary
                title="Đóng"
                icon="MenuIcon"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="updateMouseHovered(false); setToggleCollapsed(true)"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
        :class="{'navbar-narrow': !isMouseHovered}"
        :mouse-hover="isMouseHovered"
      >
        <li
          v-if="!isMouseHovered"
          class="nav-item has-sub"
        >
          <b-link
            @click="updateMouseHovered(true); setToggleCollapsed(false); resetActiveElement()"
          >
            <feather-icon
              v-b-tooltip.hover.right.v-secondary
              title="Mở"
              class="d-flex align-items-center"
              :icon="'MenuIcon'"
            />
          </b-link>
        </li>
      </vertical-nav-menu-items>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, VBTooltip } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
// import END_POINTS from '@/api/endpoints'
import { mapGetters } from 'vuex'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      navigations: 'common/navigations',
    }),
  },
  destroyed() {
    this.updateMouseHovered(false)
    this.setToggleCollapsed(true)
  },
  methods: {
    resetActiveElement() {
      const removeStyelElement = document.querySelector('.open .menu-content')
      if (removeStyelElement) {
        removeStyelElement.style.position = 'unset'
        removeStyelElement.style.backgroundColor = 'white'
      }
    },
    mapNavigations(navigations) {
      const getChildren = Children => Children.map(child => {
        let result = {
          icon: child.iconClass,
          root: child.level === 0,
          title: child.name,
          route: child.urlRewrite,
          level: child.level,
        }
        if (child.subChild.length > 0) {
          result = {
            ...result,
            children: getChildren(child.subChild),
          }
        }
        return result
      })
      return getChildren(navigations)
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      setToggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'ListIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    // const navResult = this.$axios.get(END_POINTS.CHUNG.NAV, false).then(response => {
    //   if (response.data && response.data.code === 200) {
    //     this.danhSachNav = response.data.data
    //   }
    // }
    const reSizeWindow = () => {
      const newWidth = window.innerWidth
      if (newWidth <= 1200) {
        updateMouseHovered(true)
        setToggleCollapsed(false)
        const removeStyelElement = document.querySelector('.open .menu-content')
        if (removeStyelElement) {
          removeStyelElement.style.position = 'unset'
          removeStyelElement.style.backgroundColor = 'white'
        }
      }
    }
    reSizeWindow()
    window.addEventListener('resize', reSizeWindow)
    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      setToggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
