import { ROUTE_NAME } from '@/modules/mot-cua/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

export default [
  {
    title: 'Phân quyền',
    root: true,
    svg: 'phan-quyen-icon.svg',
    permissionName: [PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_LINH_VUC.DANH_SACH, PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_VAI_TRO.DANH_SACH, PERMISSION_NAME.HANH_DONG.DANH_SACH, PERMISSION_NAME.NHAT_KY_HE_THONG.DANH_SACH, PERMISSION_NAME.HDSD.DANH_SACH],
    children: [
      // {
      //   title: 'Phân quyền thủ tục hành chính',
      //   icon: 'ArrowRightIcon',
      //   route: ROUTE_NAME1.DANH_MUC.THU_TUC_HANH_CHINH.PQ_TTHC_NND,
      //   permissionName: [PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_VAI_TRO.DANH_SACH],
      // },
      // {
      //   title: 'Phân quyền lĩnh vực',
      //   icon: 'ArrowRightIcon',
      //   route: ROUTE_NAME.MOT_CUA.PQ_LINH_VUC,
      //   permissionName: [PERMISSION_NAME.PHAN_QUYEN_LINH_VUC.DANH_SACH],
      // },
      {
        title: 'Phân quyền báo cáo lĩnh vực',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.MOT_CUA.BAO_CAO_THONG_KE.PQ_BC_LV,
        permissionName: [PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_LINH_VUC.DANH_SACH],
      },
      {
        title: 'Phân quyền báo cáo đơn vị',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.MOT_CUA.BAO_CAO_THONG_KE.PQ_BC_DV,
        permissionName: [PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_VAI_TRO.DANH_SACH],
      },
    ],
  },
  {
    title: 'Nhật ký hệ thống',
    svg: 'nhat-ki-he-thong-icon.svg',
    root: true,
    route: ROUTE_NAME.MOT_CUA.NHAT_KY_HE_THONG,
    permissionName: [PERMISSION_NAME.NHAT_KY_HE_THONG.DANH_SACH],
  },
]
