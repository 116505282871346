<template>
  <li
    v-if="canViewVerticalNavMenuLink(item) && hasPermission(item.permissionName)"
    class="nav-item"
    :class="{
      'active': isActive && !item.root,
      'root': isActive && item.root,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center w-100"
      :class="{'justify-content-between': mouseHover || child}"
      @click="cancelNavbar"
    >
      <div :class="{'narrow-wrap': !mouseHover && !child}">
        <img
          v-if="item.svg"
          v-b-tooltip.hover.right.v-secondary
          :title="item.title"
          :src="require(`@/assets/images/menu/${item.svg}`)"
          class="mr-1"
          style="width: 20px; height: 20px; margin-right: 1.1rem;"
        >
        <feather-icon
          v-else
          v-b-tooltip.hover.right.v-secondary
          :title="item.title"
          :icon="item.icon || 'CircleIcon'"
        />
        <span
          v-if="mouseHover ? true : !item.root"
          class="menu-title text-wrap"
          style="font-size: 14px;"
        >{{ t(item.title) }}</span>
        <b-badge
          v-if="item.tag"
          pill
          :variant="item.tagVariant || 'primary'"
          class="mr-1 ml-auto"
        >
          {{ item.tag }}
        </b-badge>
      </div>
      <div
        v-if="getDocumentNumber(item)"
        class="doc-number"
        :class="{'menu-link-number': mouseHover && !child, narrow: !mouseHover}"
      >
        {{ getDocumentNumber(item) }}
      </div>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge, VBTooltip } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { hasPermission } from '@/utils/permission-utils'
import { mapGetters } from 'vuex'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    mouseHover: {
      type: Boolean,
    },
    child: {
      type: Boolean,
    },
  },
  data() {
    return {
      hasPermission,
    }
  },
  computed: {
    ...mapGetters({
      documentNumber: 'common/documentNumber',
    }),
  },
  mounted() {
    const el = document.querySelector('.nav-item.active')
    if (el) {
      el.addEventListener('click', this.deactiveLink)
    }
  },
  methods: {
    deactiveLink() {
      this.$emit('deactive')
    },
    cancelNavbar() {
      this.$eventBus.$emit('cancel-navbar')
    },
    getDocumentNumber(item) {
      const doc = this.documentNumber.find(d => d.code === item.code)
      if (doc) {
        return doc.count > 99 ? '99+' : doc.count
      }
      return ''
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
<style scoped>
.menu-link-number {
  margin-right: 40px;
}
.narrow-wrap {
  width: 22px !important;
}

</style>
