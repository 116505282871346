import { ROUTE_NAME } from '@/modules/tiep-nhan-ho-so/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

export default [
  {
    title: 'Tiếp nhận hồ sơ',
    svg: 'tiep-nhan-ho-so-icon.svg',
    root: true,
    permissionName: [PERMISSION_NAME.HSTNTRUC_TIEP.DANH_SACH, PERMISSION_NAME.HSTNTRUC_TUYEN.DANH_SACH, PERMISSION_NAME.HSTNTRUC_TUYEN.BO_SUNG_DANH_SACH, PERMISSION_NAME.TIEP_NHAN_BO_SUNG.DANH_SACH],
    children: [
      {
        title: 'Hồ sơ tiếp nhận trực tiếp',
        icon: 'ArrowRightIcon',
        code: 'tiep-nhan-ho-so-truc-tiep',
        route: ROUTE_NAME.HS_TRUC_TIEP.DANH_SACH,
        permissionName: [PERMISSION_NAME.HSTNTRUC_TIEP.DANH_SACH],
      },
      {
        title: 'Hồ sơ tiếp nhận trực tuyến',
        icon: 'ArrowRightIcon',
        code: 'tiep-nhan-ho-so-truc-tuyen',
        route: ROUTE_NAME.HS_TRUC_TUYEN.DS,
        permissionName: [PERMISSION_NAME.HSTNTRUC_TUYEN.DANH_SACH, PERMISSION_NAME.HSTNTRUC_TUYEN.BO_SUNG_DANH_SACH],
      },
      {
        title: 'Tiếp nhận bổ sung',
        icon: 'ArrowRightIcon',
        code: 'tiep-nhan-ho-so-bo-sung',
        route: ROUTE_NAME.TN_BO_SUNG.DS_BS,
        permissionName: [PERMISSION_NAME.TIEP_NHAN_BO_SUNG.DANH_SACH],
      },
    ],
  },
]
