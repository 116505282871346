<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-1"
    menu-class="dropdown-menu-media"
    right
  >
    <template
      #button-content
    >
      <div
        :title="title"
        @click="layHienThiTaiBell"
      >
        <feather-icon
          :badge="badge"
          class="text-body"
          badge-classes="bg-danger"
          icon="BellIcon"
          size="21"
        />
      </div>
    </template>
    <!-- Header -->
    <li
      class="dropdown-menu-header"
      :class="notifications ? '' : 'd-none'"
    >
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Thông báo
        </h4>
        <b-badge
          pill
          class="mr-1"
          variant="light-primary"
        >
          Thông báo {{ notificationsRead }} / {{ notificationsView }} đã xem
        </b-badge>
        <div
          class="delete-all"
          :class="notifications ? '' : 'd-none'"
          @click="xoaAllNoti"
        >
          <b-badge
            pill
            variant="light-danger"
          >
            Xóa tất cả
          </b-badge>
        </div>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <div v-if="notifications">
        <b-link
          v-for="notification in notifications.listNotification"
          :key="notification.id"
        >
          <b-media>
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="listTop"
                @click="capNhapNoTification(notification) , chiTiet(notification)"
              >
                <p class="media-heading">
                  <span
                    class="font-weight-bolder"
                    :class="{ active: notification.isViewed }"
                  >
                    <a
                      href="#"
                      target="_blank"
                      :title="notification.title"
                    >{{ notification.title }}</a>
                  </span>
                </p>
                <span
                  class="notification-text"
                  :class="{ access: notification.isViewed }"
                >
                  <div v-if="notification.viewedDate"><i>Đã xem lúc {{ formatDateCheckTime(notification.viewedDate) }}</i></div>
                </span>
              </div>
              <div title="Xóa">
                <feather-icon
                  icon="XCircleIcon"
                  size="18"
                  class="danger"
                  @click="xoaNotification(notification)"
                />
              </div>
            </div>
          </b-media>
        </b-link>
      </div>

      <!-- System Notifications -->
    </vue-perfect-scrollbar>
    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      :class="notifications ? '' : 'd-none'"
      @click="readAllNoTification"
    >Đọc tất cả thông báo</b-button>
    </li>
    <div
      v-if="!notifications"
      class="text-center no-noti"
    >
      Không có thông báo!
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import END_POINTS from '@/api/endpoints'
import Ripple from 'vue-ripple-directive'
import { checkStatus, formatDateCheckTime } from '@/utils/index'
import { getValueFromStorageByKey, LOCAL_STORAGE_KEY } from '@/utils/local-storage'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userInfo')),
      title: 'Thông báo',
      isActive: false,
      notifications: [],
      badge: 0,
      notificationsView: 0,
      notificationsRead: 0,
      notificationsUnRead: 0,
      notificationsId: [],
      systemNotifications: [],
      docId: '',
      hoSo: {
        title: '',
        subtitle: '',
      },
      payload: {
        userName: '',
        pageNumber: 1,
        pageSize: 9999999,
      },
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    return {
      // notifications,
      perfectScrollbarSettings,
    }
  },
  created() {
    if (getValueFromStorageByKey(LOCAL_STORAGE_KEY.ACCESS_TOKEN)) {
      this.layHienThiTaiBell()
      this.layAllDanhSach()
    }
  },
  methods: {
    formatDateCheckTime,
    layHienThiTaiBell() {
      this.$axios.get(END_POINTS.THONG_BAO_HE_THONG.LAY_DANH_SACH, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.badge = response.data.data
        }
      })
    },
    layAllDanhSach() {
      const filter = {
        ...this.payload,
        userName: this.userData.userName || '',
      }
      this.$axios.post(END_POINTS.THONG_BAO_HE_THONG.PHAN_TRANG, filter, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.notificationsView = response.data.totalCount
          this.notifications = response.data.data
          if (response.data.data) {
            this.notificationsId = response.data.data.listNotification.map(item => item.id)
            this.notificationsRead = response.data.data.readCount
            this.notificationsUnRead = response.data.data.unReadCount
          } else {
            this.notificationsRead = 0
            this.notificationsUnRead = 0
          }
        }
      })
    },
    capNhapNoTification(data) {
      const payload = {
        listId: [data.id],
      }
      this.$axios.post(END_POINTS.THONG_BAO_HE_THONG.SUA, payload, false).then(res => {
        if (res.data && res.data.succeeded) {
          this.layAllDanhSach()
        }
      })
    },
    readAllNoTification() {
      const payload = {
        listId: this.notificationsId || [],
      }
      this.$axios.post(END_POINTS.THONG_BAO_HE_THONG.SUA, payload, false).then(res => {
        if (res.data && res.data.succeeded) {
          this.layAllDanhSach()
        }
      })
    },
    xoaAllNoti() {
      const payload = {
        listId: this.notificationsId || [],
      }
      this.$axios.post(END_POINTS.THONG_BAO_HE_THONG.XOA, payload, false).then(res => {
        if (checkStatus(res) && res.data && res.data.succeeded) {
          this.layAllDanhSach()
        }
      })
    },
    chiTiet(data) {
      if (data.navCode) {
        this.docId = data.documentId || ''
        this.$router.push({
          path: '/ho-so/chi-tiet',
          query: { id: this.docId, name: data.navCode },
        }).catch(err => err)
      } else {
        this.$toast.error('Đã có lỗi xảy ra trong quá trình xử lý!')
      }
    },
    xoaNotification(data) {
      const payload = {
        listId: [data.id],
      }
      this.$axios.post(END_POINTS.THONG_BAO_HE_THONG.XOA, payload, false).then(res => {
        if (checkStatus(res) && res.data && res.data.succeeded) {
          this.layAllDanhSach()
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.listTop{
  margin-top: 3px;
  margin-right: 5px;
}
.danger{
  color : red;
}
.active{
  font-weight : 300 !important;
}
.access{
  font-weight : normal !important;
}
.delete-all{
  cursor: pointer;
}
.no-noti{
  position: absolute;
    top: 10px;
    left: 130px;
}
</style>
