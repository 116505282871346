<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData ? userData.hoTen : '' }}
        </p>
        <span class="user-status" />
      </div>
      <!--      <b-avatar-->
      <!--        size="40"-->
      <!--        :src="userData.avatar"-->
      <!--        variant="light-primary"-->
      <!--        badge-->
      <!--        class="badge-minimal"-->
      <!--        badge-variant="success"-->
      <!--      >-->
      <!--        <feather-icon-->
      <!--          v-if="!userData.fullName"-->
      <!--          icon="UserIcon"-->
      <!--          size="22"-->
      <!--        />-->
      <!--      </b-avatar>-->
    </template>
    <!--    <b-dropdown-divider />-->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      class="link-color"
    >
      <router-link to="/thong-tin-ca-nhan">
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>Thông tin cá nhân</span>
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="openModal"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Đăng xuất</span>
    </b-dropdown-item>
    <common-modal
      ref="commonModal"
      title="Thông báo"
      size="sm"
    >
      Bạn có chắc chắn muốn đăng xuất không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="logout"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import {
  LOCAL_STORAGE_KEY,
  removeValueToStorageByKey,
} from '@/utils/local-storage'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    CommonModal,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userInfo')),
      avatarText,
      isShowFooter: false,
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        if (!this.$route.query.token) {
          this.userData = JSON.parse(localStorage.getItem('userInfo'))
        }
      },
    },
  },
  methods: {
    logout() {
      removeValueToStorageByKey(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
      removeValueToStorageByKey(LOCAL_STORAGE_KEY.PERMISSIONS)
      removeValueToStorageByKey(LOCAL_STORAGE_KEY.REFRESH_TOKEN)
      removeValueToStorageByKey(LOCAL_STORAGE_KEY.IS_LOGIN)
      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      window.location.replace(`${window.location.origin}/login`)
    },
    openModal() {
      this.isShowFooter = true
      this.$refs.commonModal.showModal()
    },
  },
}
</script>
<style lang="scss" scoped>
.link-color {
  a {
    color: rgb(51, 51, 51) !important;
    &:hover {
      color: #005bd4 !important;
    }
    &:focus {
      color: white !important;
    }
  }
}
</style>
