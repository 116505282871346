import { ROUTE_NAME } from '@/modules/xu-ly-ho-so/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

export default [
  {
    title: 'Xử lý hồ sơ',
    root: true,
    svg: 'xu-ly-ho-so-icon.svg',
    permissionName: [PERMISSION_NAME.BAN_GIAO_HO_SO.DANH_SACH, PERMISSION_NAME.YEU_CAU_BO_SUNG.DANH_SACH, PERMISSION_NAME.HO_SO_DANG_XU_LY.DANH_SACH, PERMISSION_NAME.PHAN_XU_LY_HO_SO.DANH_SACH, PERMISSION_NAME.DUYET_DE_XUAT.DANH_SACH, PERMISSION_NAME.DUYET_KY_HO_SO.DANH_SACH, PERMISSION_NAME.DUYET_GIA_HAN_HO_SO.DANH_SACH, PERMISSION_NAME.HO_SO_CAN_THU_PHI.DANH_SACH, PERMISSION_NAME.HO_SO_CHO_THU_PHI.DANH_SACH],
    children: [
      {
        title: 'Bàn giao hồ sơ',
        icon: 'ArrowRightIcon',
        code: ROUTE_NAME.BAN_GIAO_HS.NAME,
        route: ROUTE_NAME.BAN_GIAO_HS.DS,
        permissionName: [PERMISSION_NAME.BAN_GIAO_HO_SO.DANH_SACH],
      },
      {
        title: 'Yêu cầu bổ sung',
        icon: 'ArrowRightIcon',
        code: ROUTE_NAME.YEU_CAU_BS.NAME,
        route: ROUTE_NAME.YEU_CAU_BS.DS,
        permissionName: [PERMISSION_NAME.YEU_CAU_BO_SUNG.DANH_SACH],
      },
      {
        title: 'Hồ sơ đang xử lý',
        icon: 'ArrowRightIcon',
        code: ROUTE_NAME.HS_DANG_XU_LY.NAME,
        route: ROUTE_NAME.HS_DANG_XU_LY.DS,
        permissionName: [PERMISSION_NAME.HO_SO_DANG_XU_LY.DANH_SACH],
      },
      {
        title: 'Phân xử lý hồ sơ',
        icon: 'ArrowRightIcon',
        code: ROUTE_NAME.PHAN_XU_LY_HS.NAME,
        route: ROUTE_NAME.PHAN_XU_LY_HS.DS,
        permissionName: [PERMISSION_NAME.PHAN_XU_LY_HO_SO.DANH_SACH],
      },
      {
        title: 'Duyệt đề xuất',
        icon: 'ArrowRightIcon',
        code: ROUTE_NAME.DUYET_DE_XUAT.NAME,
        route: ROUTE_NAME.DUYET_DE_XUAT.DS,
        permissionName: [PERMISSION_NAME.DUYET_DE_XUAT.DANH_SACH],
      },
      {
        title: 'Duyệt ký hồ sơ',
        icon: 'ArrowRightIcon',
        code: ROUTE_NAME.DUYET_KY_HO_SO.NAME,
        route: ROUTE_NAME.DUYET_KY_HO_SO.DS,
        permissionName: [PERMISSION_NAME.DUYET_KY_HO_SO.DANH_SACH],
      },
      {
        title: 'Duyệt gia hạn hồ sơ',
        icon: 'ArrowRightIcon',
        code: ROUTE_NAME.GIA_HAN_HS.NAME,
        route: ROUTE_NAME.GIA_HAN_HS.NAME,
        permissionName: [PERMISSION_NAME.DUYET_GIA_HAN_HO_SO.DANH_SACH],
      },
      {
        title: 'Hồ sơ cần thu phí, lệ phí',
        icon: 'ArrowRightIcon',
        code: 'can-thu-phi-le-phi',
        route: ROUTE_NAME.CAN_THU_PHI_LE_PHI.DS_THU_PHI,
        permissionName: [PERMISSION_NAME.HO_SO_CAN_THU_PHI.DANH_SACH],
      },
      {
        title: 'Hồ sơ chờ thu phí, lệ phí',
        icon: 'ArrowRightIcon',
        code: 'cho-thu-phi-le-phi',
        route: ROUTE_NAME.CHO_THU_PHI_LE_PHI.DS_CHO_THU_PHI,
        permissionName: [PERMISSION_NAME.HO_SO_CHO_THU_PHI.DANH_SACH],
      },
    ],
  },
]
