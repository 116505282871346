/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import trangChu from '../../modules/trang-chu/navigation/vertical/trang-chu'
// import hoSo from '../../modules/ho-so/navigation/vertical/ho-so'
import { MENU_ROUTE_NAME, ROUTE_NAME } from '@/modules/quan-tri-he-thong/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'
import quanLyQuyTrinh from '../../modules/quan-ly-quy-trinh/navigation/vertical/quan-ly-quy-trinh'
import qldm from '../../modules/danh-muc/navigation/vertical/danh-muc'
import tnhs from '../../modules/tiep-nhan-ho-so/navigation/vertical/tiep-nhan-ho-so'
import tkqhs from '../../modules/tra-ket-qua/navigation/vertical/tra-ket-qua'
import nkq from '../../modules/nhan-ket-qua/navigation/vertical/nhan-ket-qua'
import tracuu from '../../modules/tra-cuu/navigation/vertical/tc-ho-so'
import xlhs from '../../modules/xu-ly-ho-so/navigation/vertical/index'
import mc from '../../modules/mot-cua/navigation/vertical/mot-cua'
import bctk from '../../modules/bao-cao-thong-ke/navigation/vertical/bao-cao-thong-ke'
import tc from '../../modules/trang-chu/navigation/vertical/trang-chu'

const log = {
  title: MENU_ROUTE_NAME.LOG,
  icon: 'CpuIcon',
  root: true,
  route: ROUTE_NAME.LOGS_ASYNC,
  permissionName: [PERMISSION_NAME.BAO_CAO_THONG_KE],
}

const logdvcqg = {
  title: MENU_ROUTE_NAME.LOG_DVCQG,
  icon: 'ShieldIcon',
  root: true,
  route: ROUTE_NAME.LOGS_DVCQG,
  permissionName: [PERMISSION_NAME.BAO_CAO_THONG_KE],
}

// Array of sections
export default [...tc, ...tnhs, ...xlhs, ...tkqhs, ...nkq, ...tracuu, ...quanLyQuyTrinh, ...qldm, ...mc, ...bctk, log, logdvcqg]
