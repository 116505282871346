<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header col-md-12 p-0 mb-1"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left "
      cols="12"
      md="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col
          cols="12"
          class="p-0"
        >
          <div class="breadcrumb-wrapper">
            <b-breadcrumb v-if="$route.meta.isCheckName || nameRouter">
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :class="(nameRouter && item.name && item.name.includes(nameRouter)) || item.active ? 'breadcrumb-item' : 'd-none'"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
            <b-breadcrumb v-else>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  data() {
    return {
      nameRouter: null,
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        if (newVal?.name !== undefined) {
          this.nameRouter = newVal.name
        } else {
          this.nameRouter = null
        }
      },
    },
  },
}
</script>
