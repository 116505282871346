import { ROUTE_NAME } from '@/modules/tra-cuu/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

export default [
  {
    title: 'Tra cứu',
    svg: 'tra-cuu-icon.svg',
    root: true,
    code: ROUTE_NAME.TC_HO_SO.NAME,
    route: ROUTE_NAME.TC_HO_SO.DS,
    permissionName: [PERMISSION_NAME.TRA_CUU],
  },
]
