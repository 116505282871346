import { ROUTE_NAME } from '@/modules/bao-cao-thong-ke/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

export default [
  {
    title: 'Báo cáo thống kê',
    svg: 'bao-cao-thong-ke-icon.svg',
    root: true,
    route: ROUTE_NAME.BAO_CAO_THONG_KE.DANH_SACH_THONG_KE,
    permissionName: [PERMISSION_NAME.BAO_CAO_THONG_KE],
  },
  {
    title: 'Nhập liệu báo cáo',
    icon: 'BarChart2Icon',
    root: true,
    route: ROUTE_NAME.BAO_CAO_THONG_KE.NHAP_LIEU_THONG_KE,
    permissionName: [PERMISSION_NAME.BAO_CAO_THONG_KE],
  },
]
