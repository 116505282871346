import { ROUTE_NAME } from '@/modules/danh-muc/constants/constants'
import { ROUTE_NAME as ROUTE_NAME1, MENU_ROUTE_NAME as MENU_ROUTE_NAME1 } from '@/modules/quan-tri-he-thong/constants/constants'
import { ROUTE_NAME as ROUTE_NAME2 } from '@/modules/mot-cua/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

export const PERMISSION = [
  PERMISSION_NAME.LINH_VUC.DANH_SACH,
  PERMISSION_NAME.QUOC_TICH.DANH_SACH,
  PERMISSION_NAME.BIEU_MAU.DANH_SACH,
  PERMISSION_NAME.THOI_GIAN_LAM_VIEC.DANH_SACH,
  PERMISSION_NAME.LOAI_TRANG_THAI.DANH_SACH,
  PERMISSION_NAME.TRANG_THAI.DANH_SACH,
  PERMISSION_NAME.NHOM_PHIEU.DANH_SACH,
  PERMISSION_NAME.DAN_TOC.DANH_SACH,
  PERMISSION_NAME.QUOC_TICH.DANH_SACH,
  PERMISSION_NAME.BAO_CAO.DANH_SACH,
  PERMISSION_NAME.HOI_DAP.DANH_SACH,
  PERMISSION_NAME.HDSD.DANH_SACH,
]

export default [
  {
    title: 'Quản lý thủ tục hành chính',
    root: true,
    svg: 'thu-tuc-hanh-chinh-icon.svg',
    route: ROUTE_NAME.THU_TUC_HANH_CHINH.DS_TTHC,
    permissionName: [PERMISSION_NAME.QUOC_TICH.DANH_SACH],
  },
  {
    title: 'Quản lý danh mục',
    root: true,
    svg: 'danh-muc-icon.svg',
    permissionName: [...PERMISSION],
    children: [
      {
        title: 'Danh mục lĩnh vực',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.LINH_VUC,
        permissionName: [PERMISSION_NAME.LINH_VUC.DANH_SACH],
      },
      {
        title: 'Danh mục giấy tờ',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.GIAY_TO,
        permissionName: [PERMISSION_NAME.QUOC_TICH.DANH_SACH],
      },
      {
        title: MENU_ROUTE_NAME1.FORM.DANH_SACH,
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME1.FORM.DANH_SACH,
        permissionName: [PERMISSION_NAME.BIEU_MAU.DANH_SACH],
      },
      {
        title: MENU_ROUTE_NAME1.NAVIGATION,
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME1.NAVIGATION,
        permissionName: [PERMISSION_NAME.BIEU_MAU.DANH_SACH],
      },
      {
        title: 'Thời gian làm việc trong tuần',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.THOI_GIAN_LAM_VIEC_TRONG_TUAN,
        permissionName: [PERMISSION_NAME.THOI_GIAN_LAM_VIEC.DANH_SACH],
      },
      {
        title: 'Danh mục loại trạng thái',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.LOAI_TRANG_THAI,
        permissionName: [PERMISSION_NAME.LOAI_TRANG_THAI.DANH_SACH],
      },
      {
        title: 'Danh mục trạng thái',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.TRANG_THAI,
        permissionName: [PERMISSION_NAME.TRANG_THAI.DANH_SACH],
      },
      {
        title: 'Danh mục nhóm phiếu',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.NHOM_PHIEU,
        permissionName: [PERMISSION_NAME.NHOM_PHIEU.DANH_SACH],
      },
      {
        title: 'Danh mục mẫu phiếu',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.MAU_PHIEU,
        permissionName: [PERMISSION_NAME.MAU_PHIEU.DANH_SACH],
      },
      {
        title: 'Danh mục dân tộc',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.DAN_TOC,
        permissionName: [PERMISSION_NAME.DAN_TOC.DANH_SACH],
      },
      {
        title: 'Danh mục quốc tịch',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.QUOC_TICH,
        permissionName: [PERMISSION_NAME.QUOC_TICH.DANH_SACH],
      },
      {
        title: 'Danh mục báo cáo',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.BAO_CAO,
        permissionName: [PERMISSION_NAME.BAO_CAO.DANH_SACH],
      },
      {
        title: 'Danh mục hành động',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME2.MOT_CUA.HANH_DONG,
        permissionName: [PERMISSION_NAME.HANH_DONG.DANH_SACH],
      },
      {
        title: 'Hướng dẫn sử dụng',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME2.MOT_CUA.HUONG_DAN_SU_DUNG,
        permissionName: [PERMISSION_NAME.HDSD.DANH_SACH],
      },
    ],
  },
  {
    title: 'Tiện ích',
    svg: 'tien-ich-icon.svg',
    root: true,
    permissionName: [PERMISSION_NAME.CAU_HINH_CONG_THANH_TOAN.DANH_SACH, PERMISSION_NAME.CAU_HINH_THAM_SO_HE_THONG.DANH_SACH],
    children: [
      {
        title: 'Cấu hình cổng thanh toán',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.TIEN_ICH.CONG_THANH_TOAN,
        permissionName: [PERMISSION_NAME.CAU_HINH_CONG_THANH_TOAN.DANH_SACH],
      },
      {
        title: 'Cấu hình tham số hệ thống',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.TIEN_ICH.TSHT,
        permissionName: [PERMISSION_NAME.CAU_HINH_THAM_SO_HE_THONG.DANH_SACH],
      },
      {
        title: 'Thông tin ký số',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.TIEN_ICH.THONG_TIN_KY_SO,
        permissionName: [PERMISSION_NAME.DANH_MUC_KY_SO],
      },
    ],
  },
  {
    title: 'Đánh giá, khảo sát',
    svg: 'danh-gia-khao-sat-icon.svg',
    root: true,
    permissionName: [PERMISSION_NAME.HOI_DAP.DANH_SACH, PERMISSION_NAME.CAU_HINH_DANH_GIA.DANH_SACH],
    children: [
      {
        title: 'Hỏi đáp thắc mắc',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_GIA_KHAO_SAT.HOI_DAP,
        permissionName: [PERMISSION_NAME.HOI_DAP.DANH_SACH],
      },
      {
        title: 'Nhóm tiêu chí',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_GIA_KHAO_SAT.NHOM_TIEU_CHI,
        permissionName: [PERMISSION_NAME.NHOM_TIEU_CHI.DANH_SACH],
      },
      {
        title: 'Câu hỏi khảo sát',
        icon: 'ArrowRightIcon',
        route: ROUTE_NAME.DANH_GIA_KHAO_SAT.CAU_HINH_DANH_GIA,
        permissionName: [PERMISSION_NAME.CAU_HINH_DANH_GIA.DANH_SACH],
      },
    ],
  },
]
